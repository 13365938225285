<template>
    <div class="main">
        <div class="error" v-if="error" >
            <img src="./../assets/images/icon-error.png" alt="error" />
            <p v-html="error"></p>
        </div>
        <div class="webinar-container" v-if="!error && webinar">
            <h1>{{webinar.name}}</h1>
            <p class="infos">
                At anytime during the video, you may ask your questions by clicking on the dedicated button "Pause & Ask questions".<br />
                The video will be paused automatically.<br />
                <br />
                At the end of the presentation, when you have completed all your questions, please click on the "Send my questions" button in order to send them to the presenter.<br />
                Then, you will receive the answers of your questions by the presenter.
            </p>
            <h2 v-if="!acces_start">This session is not opened yet </h2>
            <h2 v-if="!acces_end">This session is closed </h2>
            <div class="row">
                <div class="col-video" v-if="acces_end && acces_start">               
                    <video width="800" controls ref="video" @click="openQuestion">
                        <source :src="`${$store.state.URL}videos/${webinar.video_link}`" type="video/mp4">
                    </video>
                    <div class="video-btn" v-if="btnDisplay">
                        <div class="btn-form" @click="openQuestion">Pause & Ask questions</div>
                    </div>
                    <div class="modal-question" v-if="addQuestion">
                        <div class="modal-close" @click="closeModal"></div>
                        <div class="question-form" >
                            <h4>Ask a question</h4>
                            <div class="current-time">Video Time: {{ videoCurrentTime }}sec</div>
                            <textarea v-model="question" rows="5" maxlength="250"></textarea>
                            <div class="modal-btn">
                                <div class="btn-form btn-cancel" @click="closeModal">Cancel</div>
                                <div class="btn-form" @click="populateForm">Add</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-questions">
                    <div class="questions-container">
                        <h3>My questions</h3>
                        <div class="questions-item" v-for="(item, idx) in questionsList" :key="idx">
                            <div class="question-time">Video Time: {{ item.currentTime}}sec</div>
                            <p class="question-text">{{ item.question}}</p>
                            <p class="anwser-text" v-if="item.answer"><strong>Answer:</strong> {{ item.answer }}</p>
                        </div>
                        <div class="questions-btn" v-if="btnDisplay && acces_end && acces_start">
                            <div class="btn-form" v-if="!loading" @click="sendQuestions"  >Send my questions</div>
                            <Loader v-else></Loader>
                        </div>
                        <div class="form-succes" v-if="!btnDisplay && !success">You have already sent your questions for this webinar</div>
                        <div class="form-failed" v-if="errorQuestions" v-html="errorQuestions"></div>
                        <div class="form-succes" v-if="success">Your questions have been sent!</div>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm
            v-if="displayModal"
            :contentModal='`
                <br />
                At anytime during the video, you may ask your questions by clicking on the dedicated button "Pause & Ask questions".<br />
                The video will be paused automatically.<br />
                <br />
                At the end of the presentation, when you have completed all your questions, please click on the "Send my questions" button in order to send them to the presenter.<br />
                <br />
                Then, you will receive the answers of your questions by the presenter.`' 
            v-on:hide="displayModal = false"
            v-on:confirm="displayModal = false"
            type="1"
        >
        </ModalConfirm>
    </div>
</template>

<script>
import store from './../store'
import axios from 'axios'
import Loader from './../components/Loader'
import ModalConfirm from './../components/ModalConfirm'

export default {
    name : 'Webinar',
    components : {
        Loader, ModalConfirm
    },
    data() {
        return {
            webinar : null,
            error : null,
            question : null,
            addQuestion : false,
            videoCurrentTime : null,
            questionsList : [],
            errorQuestions : null,
            success : false,
            btnDisplay : true,
            loading : false,
            displayModal : true,
            acces_start : false,
            acces_end : false
        }
    },

    methods: {
        playVideo() {
            this.$refs.video.play()
        },
        openQuestion() {
            this.$refs.video.pause()
            const currentTime = this.$refs.video.currentTime
            this.addQuestion = true
            this.videoCurrentTime = this.getTime(Math.round(currentTime))
        },
        populateForm() {
            const newQuestion = {
                currentTime : this.videoCurrentTime,
                question : this.question
            }
            this.questionsList.push(newQuestion)
            this.addQuestion = false
            this.videoCurrentTime = null
            this.question = null
            this.$refs.video.play()
        },
        closeModal() {
            this.addQuestion = false
            this.videoCurrentTime = null
            this.question = null
            this.$refs.video.play()
        },
        sendQuestions() {
            this.errorQuestions = null
            this.succes = false
            this.loading = true

            let params = {
                user_id : store.state.AUTH.user_id,
                webinar_id : this.$route.params.id,
                question : this.questionsList,
                token : store.state.JWT_TOKEN
            }

            let vm = this

            axios
                .post(store.getters.getURL + "webinars/addQuestions.php", params )
                .then(function (response) { 
                    vm.loading = false
                    if(response.data.state == 'success') {
                        vm.success = true
                        vm.btnDisplay = false
                    }else {
                         if(response.data.authError) {
                            store.commit('logout')
                            vm.$router.push('/login')
                        }else {
                             vm.errorQuestions = "An error has occured: " + response.data.retour
                        }
                    }
                })
                .catch((err) => {
                    vm.errorQuestions = "An error has occured: " + err
                });
        },
        getTime(timer) {
            let minutes  = Math.floor(timer / 60);
            if(minutes < 9) {
                minutes = '0' + minutes
            } 
            let seconds  = Math.floor(timer % 60);
            if (seconds < 9 ) {
                seconds = '0' +seconds
            } 
            return minutes+':'+seconds;
        }
    },
    computed: {
        session() {
            if(this.webinar.lang == 2){
                return 'Session française';
            }else {
                return 'English session';
            }
        },

    },
    mounted() {

        let vm = this
        this.error = null

        axios
            .get(store.getters.getURL + "webinars/getWebinarAcces.php?user="+this.$store.state.AUTH.user_id+"&webinar="+this.$route.params.id+"&token="+store.state.JWT_TOKEN )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.webinar = response.data.res
                    if(response.data.questions.length > 0 ) {
                        response.data.questions.forEach(function(e){
                            let newQuestion = {
                                currentTime : e.webinar_video_question_current_time,
                                question : e.webinar_video_question_question,
                                answer : e.webinar_video_question_answer
                            }
                            vm.questionsList.push(newQuestion)
                        });
                        vm.btnDisplay = false
                        vm.displayModal = false
                    }
                    vm.acces_start = response.data.webinar_start
                    vm.acces_end = response.data.webinar_end
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.error = response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.error = 'An error has occured:' +  err
            });
    }
}
</script>

<style lang="scss" scoped>
    .main {
        width:1450px;
        max-width: 95%;
        margin:50px auto 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    h1 {
        margin-bottom:20px;
    }

    .infos {
        text-align: center;
        color: $secondaryColor;
    }

    h2 {
        text-align: center;
        margin-top:20px;
    }
    
    .questions-container {
        padding-left: 10%;
        padding-right: 20px;
    }

    .webinar-container {
        display:flex;
        width:100%;
        flex-direction: column;
        margin-bottom:80px;
    }

    .row {
        margin-top:40px;
    }

    .col-video {
        min-width:60%;
    }

    .col-question {
        width:40%;
    }

    .col-question.full {
        width:100%;
    }

    video {
        width: 100%;
    }

    .video-btn {
        display: flex;
        justify-content: center;
        margin:20px 0;
    }

    .modal-question {
        background:rgba(0,0,0, 0.5);
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .question-form {
        background: #fff;        
        border-radius:10px;
        width: 600px;
        max-width: calc(90% - 80px);
        padding:20px 40px;
        display: flex;
        flex-direction: column;
    }

    .current-time {
        font-style:italic;
    }

    textarea {
        margin:20px 0;
        width:99%;
        font-family: $font;
    }

    .questions-item {
        padding:20px 0;
        border-bottom: 1px solid $secondaryColor;
    }

    .questions-item:last-child {
        border-bottom:none;
    }

    .send-sep {
        height:40px;
        width:100%;
    }

    .question-time {
        color:$mainColor;
        font-size:15px;
    }

    .col-questions {
        width:100%;
    }

    .question-text {
        margin:10px 0 0 0 ;
        color:$secondaryColor;
        font-size:15px;
    }

    .anwser-text {
        margin:10px 0 0 0 ;
        color:#9e9e9e;
        font-size:15px;
        font-style:italic;
    }

    h3 {
        font-size:18px;
        font-family: $fontBold;
        color:$secondaryColor;
    }

    h4 {
        color:$secondaryColor;
        font-size:20px;
        text-align: center;
    }

    .questions-btn {
        display: flex;
        background: #fff;
        margin-top:-20px;
        justify-content: center;
        padding-top:40px;
    }

    .modal-close {
        position: absolute;
        top:15px;
        right:15px;
        height:20px;
        width:20px;
        background: url('./../assets/images/icon-close.png') ;
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .modal-btn {
        display: flex;
        justify-content: center;
    }

    .modal-btn .btn-form {
        margin:0 10px;
        border:1px solid $secondaryColor;
    }

    .btn-form.btn-cancel {
        background:#fff;
        color:$secondaryColor;
    }

    .modal-btn .btn-form:hover {
        border:1px solid $mainColor;
    }

    .btn-form.btn-cancel:hover {
        background: $mainColor;
        color:#fff;
    }

    .questions-already-send {
        border:1px solid $secondaryColor;
        color:$secondaryColor;
        padding:10px 20px;
        text-align: center;
        border-radius:10px;
        margin-top:40px;
    }


    @media screen and (max-width: 768px) {
        .questions-container {
            padding-left: 20px;
            padding-right: 20px;
        }

        .col-questions {
            padding-bottom: 20px;
        }

        .questions-container h3 {
            text-align: center;
        }

        .questions-btn .btn-form {
            background:$mainColor;
        }
    }


</style>